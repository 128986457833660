"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Label = require("@oberoninternal/travelbase-ds/components/primitive/Label");
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Radio = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/Radio"));
var _CheckboxFilter = require("./CheckboxFilter");
var __jsx = _react.default.createElement;
const RadioFilter = ({
  hits,
  fieldName,
  id,
  label,
  fieldValue,
  categoryHandle
}) => {
  const [, {
    value
  }, {
    setValue
  }] = (0, _formik.useField)({
    name: fieldName,
    value: fieldValue,
    type: 'radio'
  });
  return __jsx(_grid.Flex, {
    my: 4
  }, __jsx(_Radio.default, {
    id: id,
    value: fieldValue,
    checked: value.includes(fieldValue),
    onChange: e => {
      setValue([...value.filter(val => !val.startsWith(categoryHandle)), e.currentTarget.value]);
      (0, _CheckboxFilter.scrollTopOnChange)();
    }
  }), __jsx(_Label.Label, {
    htmlFor: id
  }, __jsx(_grid.Box, null, label, hits && __jsx(HitCount, null, ` (${hits})`))));
};
var _default = exports.default = RadioFilter;
const HitCount = _styledComponents.default.span.withConfig({
  displayName: "RadioFilter__HitCount",
  componentId: "sc-1y009uv-0"
})(["color:", ";"], ({
  theme
}) => theme.colors.neutral['50']);