"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _Map = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Map.svg"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _FilterMobile = _interopRequireDefault(require("./svg/FilterMobile"));
var _ListBullets = _interopRequireDefault(require("./svg/ListBullets.svg"));
var _Sort = _interopRequireDefault(require("./svg/Sort.svg"));
var __jsx = _react.default.createElement;
const SearchBottomBar = ({
  allowedActions,
  filtersActive,
  onMapClick,
  onListClick,
  onFiltersClick,
  onSortClick
}) => __jsx(_react.default.Fragment, null, __jsx(Container, {
  className: "lt-l"
}, allowedActions.map(type => {
  switch (type) {
    case 'filters':
      return __jsx(StyledButton, {
        key: type,
        onClick: onFiltersClick
      }, __jsx(_FilterMobile.default, {
        active: filtersActive
      }), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
        id: "JwI+G2",
        defaultMessage: [{
          "type": 0,
          "value": "filters"
        }]
      })));
    case 'map':
      return __jsx(StyledButton, {
        key: type,
        onClick: () => onMapClick?.()
      }, __jsx(_Map.default, null), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
        id: "c1IYM/",
        defaultMessage: [{
          "type": 0,
          "value": "Kaart"
        }]
      })));
    case 'list':
      return __jsx(StyledButton, {
        key: type,
        onClick: () => onListClick?.()
      }, __jsx(_ListBullets.default, null), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
        id: "YsNoED",
        defaultMessage: [{
          "type": 0,
          "value": "Lijst"
        }]
      })));
    case 'sort':
      return __jsx(StyledButton, {
        key: type,
        onClick: onSortClick
      }, __jsx(_Sort.default, null), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
        id: "Y1jaCK",
        defaultMessage: [{
          "type": 0,
          "value": "sorteren"
        }]
      })));
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(type);
  }
})));
var _default = exports.default = SearchBottomBar;
const Container = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "SearchBottomBar__Container",
  componentId: "zg7tq9-0"
})(["position:fixed;left:var(--wrapperPadding);right:var(--wrapperPadding);height:5.6rem;display:flex;align-items:center;justify-content:space-between;z-index:", ";> * + *{margin-left:0.6rem;}bottom:0.8rem;@media screen and (min-width:", "){height:6.4rem;bottom:2.4rem;}"], ({
  theme
}) => theme.zIndices.sticky, ({
  theme
}) => theme.mediaQueries.s);
const StyledButton = (0, _styledComponents.default)(_Button.default).attrs({
  variant: 'outline'
}).withConfig({
  displayName: "SearchBottomBar__StyledButton",
  componentId: "zg7tq9-1"
})(["flex:3;height:100%;border-radius:0.8rem;border:1px solid ", ";:active,:focus{border-color:", ";}&&{background:", ";box-shadow:0 1.6rem 2.4rem 0 rgba(59,118,160,0.07),0 2.4rem 8rem 0 rgba(59,118,160,0.1);}font-size:10px !important;> span{display:flex;flex-direction:column;text-transform:capitalize;> * + *{margin-left:0 !important;margin-top:0.5rem;}}@media screen and (min-width:", "){font-size:inherit;}"], ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.colors.neutral['40'], ({
  theme
}) => theme.colors.neutral['5'], ({
  theme
}) => theme.mediaQueries.s);