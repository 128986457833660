"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _useRandom = require("@oberoninternal/travelbase-ds/hooks/useRandom");
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _lodash = _interopRequireDefault(require("lodash.omit"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _FilterListCategory = _interopRequireDefault(require("./FilterListCategory"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _Filter = _interopRequireDefault(require("./svg/Filter.svg"));
var __jsx = _react.default.createElement;
const categorySize = 4;

/**
 * Renders a list from the given `categories` and can optionally render a different `CategoryComponent` by using the `categoryComponents` prop. It also contains a spinner out of the box, which you can use by using the `loading` prop.
 */
const FilterList = props => {
  if (props.loading) {
    return __jsx(_Stack.default, (0, _extends2.default)({
      spacing: 5
    }, (0, _lodash.default)(props, 'loading')), new Array(3).fill(null).map((_, i) => __jsx(Loader, {
      key: i
    })));
  }
  return __jsx(FilterListContent, props);
};
var _default = exports.default = FilterList;
const FilterListContent = props => {
  const {
    categoryComponents
  } = props;
  const {
    open,
    onOpen
  } = (0, _useSesame.default)();
  const categories = props.categories.filter(category => !!category.filters?.length);
  const filtersAmount = categories.flatMap(category => category.filters).length;
  if (!filtersAmount) {
    return null;
  }
  return __jsx(_Stack.default, (0, _extends2.default)({
    spacing: 4
  }, props, {
    mt: 4,
    pt: 4
  }), categories.slice(0, open ? undefined : categorySize).map(category => {
    const CategoryComponent = categoryComponents?.[category.handle] ?? _FilterListCategory.default;
    return __jsx(CategoryComponent, {
      key: category.handle,
      category: category
    });
  }), !open && categories.length > categorySize && __jsx(StyledTextButton, {
    style: {
      display: 'inline-flex'
    },
    onClick: onOpen,
    size: "tiny"
  }, __jsx(FilterIcon, null), __jsx(_reactIntl.FormattedMessage, {
    id: "UpAWMX",
    defaultMessage: [{
      "type": 0,
      "value": "Meer filters tonen"
    }]
  })));
};
const Loader = () => {
  const random = (0, _useRandom.useRandom)(130, 200);
  return __jsx(_grid.Box, null, __jsx(_reactLoadingSkeleton.default, {
    width: random,
    height: "1.5rem"
  }), __jsx(_Stack.default, {
    mt: 3,
    spacing: 3
  }, new Array(4).fill(null).map((_, i) => __jsx(_Stack.default, {
    alignItems: "center",
    variant: "inline",
    spacing: 4,
    key: i
  }, __jsx(_reactLoadingSkeleton.default, {
    width: "2.5rem",
    height: "2.5rem"
  }), __jsx(_reactLoadingSkeleton.default, {
    width: "10rem",
    height: "1.5rem"
  })))));
};
const FilterIcon = (0, _styledComponents.default)(_Filter.default).withConfig({
  displayName: "FilterList__FilterIcon",
  componentId: "hkdqis-0"
})(["color:inherit;"]);
const StyledTextButton = (0, _styledComponents.default)(_TextButton.default).withConfig({
  displayName: "FilterList__StyledTextButton",
  componentId: "hkdqis-1"
})(["color:", ";span{font-weight:600;}&:hover span{border:none;}span{font-weight:600;svg{margin-right:1.2rem;}}transition:color 0.15s ease-in-out;"], ({
  theme
}) => theme.colors.neutral['100']);