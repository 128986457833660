"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scrollTopOnChange = exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _Checkbox = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/Checkbox"));
var _Label = require("@oberoninternal/travelbase-ds/components/primitive/Label");
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
const _excluded = ["onChange"];
var __jsx = _react.default.createElement;
const scrollTopOnChange = () => {
  const pageEl = document.getElementById('search-page');
  if (pageEl) {
    const rect = pageEl.getBoundingClientRect();
    if (rect.top < 0) {
      window.scrollTo(0, pageEl.offsetTop - 32);
    }
  }
};
exports.scrollTopOnChange = scrollTopOnChange;
const CheckboxFilter = ({
  hits,
  fieldName,
  id,
  label,
  fieldValue
}) => {
  const [_ref] = (0, _formik.useField)({
    name: fieldName,
    value: fieldValue,
    type: 'checkbox'
  });
  const {
      onChange
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(_grid.Flex, {
    my: 4
  }, __jsx(_Checkbox.default, (0, _extends2.default)({
    id: id,
    onChange: e => {
      onChange(e);
      scrollTopOnChange();
    }
  }, props)), __jsx(_Label.Label, {
    htmlFor: id
  }, __jsx(_grid.Box, null, label, hits && __jsx(HitCount, null, ` (${hits})`))));
};
var _default = exports.default = CheckboxFilter;
const HitCount = _styledComponents.default.span.withConfig({
  displayName: "CheckboxFilter__HitCount",
  componentId: "sc-197ux1m-0"
})(["color:", ";"], ({
  theme
}) => theme.colors.neutral['50']);