"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StyledViewToggle = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _ButtonGroup = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/ButtonGroup"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Map = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Map.svg"));
var _ListBullets = _interopRequireDefault(require("../svg/ListBullets.svg"));
var _reactIntl = require("react-intl");
const _excluded = ["allowMap", "allowList", "onMap", "onList"];
var __jsx = _react.default.createElement;
const ViewToggle = _ref => {
  let {
      allowMap,
      allowList,
      onMap,
      onList
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(StyledViewToggle, props, __jsx(_Button.default, {
    variant: !allowMap ? 'secondary' : 'outline',
    onClick: onMap
  }, __jsx(_Map.default, {
    width: 16,
    height: 16
  }), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
    id: "c1IYM/",
    defaultMessage: [{
      "type": 0,
      "value": "Kaart"
    }]
  }))), __jsx(_Button.default, {
    variant: !allowList ? 'secondary' : 'outline',
    onClick: onList
  }, __jsx(_ListBullets.default, {
    width: 16,
    height: 16
  }), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
    id: "YsNoED",
    defaultMessage: [{
      "type": 0,
      "value": "Lijst"
    }]
  }))));
};
const StyledViewToggle = exports.StyledViewToggle = (0, _styledComponents.default)(_ButtonGroup.default).withConfig({
  displayName: "ViewToggle__StyledViewToggle",
  componentId: "sv7chy-0"
})(["display:none;--buttonContainerRadius:calc(", " + ", "*0.5);@media screen and (min-width:", "){display:flex;> button:first-of-type{border-top-left-radius:var(--buttonContainerRadius) !important;border-bottom-left-radius:var(--buttonContainerRadius) !important;}> button:last-of-type{border-top-right-radius:var(--buttonContainerRadius) !important;border-bottom-right-radius:var(--buttonContainerRadius) !important;}}@media screen and (min-width:", "){> button{padding:0 2.4rem;}}"], ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.mediaQueries.l, ({
  theme
}) => theme.mediaQueries.xl);
var _default = exports.default = ViewToggle;