"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _TagButton = _interopRequireDefault(require("./TagButton"));
var __jsx = _react.default.createElement;
const SearchFilterTag = props => __jsx(_grid.Box, {
  pr: 3,
  mt: [4, null, 0],
  mb: [null, null, 3]
}, __jsx(_TagButton.default, (0, _extends2.default)({
  type: "button"
}, props)));
var _default = exports.default = SearchFilterTag;