"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _SearchFilterTag = _interopRequireDefault(require("./SearchFilterTag"));
var _Divider = _interopRequireDefault(require("./Divider"));
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const SearchFilterTags = ({
  shouldAutoSave,
  categories
}) => {
  const {
    submitForm,
    values,
    setFieldValue
  } = (0, _formik.useFormikContext)();
  const createOnRemoveHandler = (name, activeHandle) => () => {
    setFieldValue(name, values[name]?.filter(handle => handle !== activeHandle));
    if (!shouldAutoSave) {
      submitForm();
    }
  };
  const activeFilters = categories.flatMap(category => category.filters?.map(filter => _objectSpread(_objectSpread({}, filter), {}, {
    category: category.formikKey ?? category.handle
  })) ?? []).filter(filter => values[filter.category]?.includes(filter.handle));
  if (!activeFilters?.length) {
    return null;
  }
  return __jsx(_grid.Box, {
    mb: 4
  }, __jsx(_Body.default, {
    className: "gt-s",
    variant: "micro",
    style: {
      fontWeight: 500,
      marginBottom: '1.6rem'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "/kgG1C",
    defaultMessage: [{
      "type": 0,
      "value": "Geselecteerde filters"
    }]
  })), __jsx(Container, null, activeFilters.map(({
    category: group,
    handle,
    label
  }) => {
    const onClickHandler = createOnRemoveHandler(group, handle);
    return __jsx(_SearchFilterTag.default, {
      key: handle,
      onClick: onClickHandler
    }, label);
  })), __jsx(_Divider.default, null));
};
var _default = exports.default = SearchFilterTags;
const Container = (0, _styledComponents.default)(_grid.Flex).attrs({
  width: 1
}).withConfig({
  displayName: "SearchFilterTags__Container",
  componentId: "sc-1x0ehli-0"
})(["> *{flex-shrink:0;}margin-bottom:", ";@media screen and (max-width:", "px){overflow-x:auto;width:calc(100% + var(--wrapperPadding) * 2);padding-bottom:0.8rem;}@media screen and (min-width:", "){flex-wrap:wrap;}"], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.mediaQueriesValues.s - 1, ({
  theme
}) => theme.mediaQueries.s);