"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _CheckboxFilter = _interopRequireDefault(require("./CheckboxFilter"));
var _Dots = _interopRequireDefault(require("./svg/Dots.svg"));
var _Divider = _interopRequireDefault(require("./Divider"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _RadioFilter = _interopRequireDefault(require("./RadioFilter"));
var __jsx = _react.default.createElement;
const FILTER_SIZE = 4;
const FilterListCategory = ({
  category,
  type = 'checkbox'
}) => {
  const {
    open,
    onOpen
  } = (0, _useSesame.default)();
  if (!category.filters?.length) {
    return null;
  }
  const FilterComponent = type === 'checkbox' ? _CheckboxFilter.default : _RadioFilter.default;
  return __jsx(FilterContainer, null, __jsx(_grid.Box, null, __jsx(FilterCaption, {
    variant: "micro"
  }, category.label), category.filters.slice(0, open ? undefined : FILTER_SIZE).map(filter => __jsx(FilterComponent, {
    key: filter.handle,
    label: filter.label,
    fieldValue: filter.handle,
    fieldName: category.formikKey ?? category.handle,
    id: filter.handle,
    hits: filter.hits,
    categoryHandle: category.handle
  })), !open && category.filters.length > FILTER_SIZE && __jsx(StyledTextButton, {
    onClick: onOpen,
    size: "tiny"
  }, __jsx(DotsIcon, null), __jsx(_reactIntl.FormattedMessage, {
    id: "X20tgO",
    defaultMessage: [{
      "type": 0,
      "value": "Alle "
    }, {
      "type": 1,
      "value": "numberOfFilters"
    }, {
      "type": 0,
      "value": " tonen"
    }],
    values: {
      numberOfFilters: category.filters.length
    }
  }))), __jsx(_Divider.default, null));
};
var _default = exports.default = FilterListCategory;
const DotsIcon = (0, _styledComponents.default)(_Dots.default).withConfig({
  displayName: "FilterListCategory__DotsIcon",
  componentId: "sc-4bxyzy-0"
})(["color:inherit;"]);
const FilterContainer = _styledComponents.default.div.withConfig({
  displayName: "FilterListCategory__FilterContainer",
  componentId: "sc-4bxyzy-1"
})(["display:flex;flex-flow:column;> span + span{margin-top:1rem;}"]);
const FilterCaption = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "FilterListCategory__FilterCaption",
  componentId: "sc-4bxyzy-2"
})(["font-weight:bold;"]);
const StyledTextButton = (0, _styledComponents.default)(_TextButton.default).withConfig({
  displayName: "FilterListCategory__StyledTextButton",
  componentId: "sc-4bxyzy-3"
})(["color:", ";span{font-weight:600;svg{margin-right:1.2rem;}}&:hover{color:", ";span:after{border:none;}}span{font-weight:600;svg{margin-right:1.2rem;}}"], ({
  theme
}) => theme.colors.neutral['90'], ({
  theme
}) => (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.textAction));