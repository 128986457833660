"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Cross = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/Cross"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
const _excluded = ["children"];
var __jsx = _react.default.createElement;
const TagButton = _ref => {
  let {
      children
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(StyledButton, props, __jsx(_Cross.default, {
    width: 10,
    height: 10
  }), children);
};
var _default = exports.default = TagButton;
const StyledButton = (0, _styledComponents.default)(_Button.default).attrs({
  rounded: true,
  size: 'small'
}).withConfig({
  displayName: "TagButton__StyledButton",
  componentId: "sc-1fkue0b-0"
})(["&&{> span > svg{margin-right:0.8rem;}padding:0 1.6rem;transition:box-shadow 0.2s ease-in-out;background:", ";color:", ";--border-color:transparent;:focus,:active{--border-color:", ";}:hover{--border-color:", ";}}"], ({
  theme
}) => theme.colorAssignments.tagButton ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.tagButton.backgroundColor) : theme.colors.secondary['5'], ({
  theme
}) => theme.colorAssignments.tagButton ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.tagButton.color) : theme.colors.secondary['80'], ({
  theme
}) => theme.colorAssignments.tagButton ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.tagButton.borderColor) : theme.colors.secondary['80'], ({
  theme
}) => theme.colorAssignments.tagButton ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.tagButton.borderColor) : theme.colors.secondary['70']);