"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("react");
var _useQueryParams = _interopRequireDefault(require("./useQueryParams"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const useFiltersActive = filterParamsConfig => {
  const [params, setParams] = (0, _useQueryParams.default)(filterParamsConfig);
  const filtersActive = (0, _react.useMemo)(() => Object.entries(params).some(([, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  }), [params]);
  const removeActiveFilters = (0, _react.useCallback)(() => setParams(Object.keys(filterParamsConfig).reduce((newParams, key) => _objectSpread(_objectSpread({}, newParams), {}, {
    [key]: undefined
  }), {})), [filterParamsConfig, setParams]);
  return [filtersActive, removeActiveFilters];
};
var _default = exports.default = useFiltersActive;