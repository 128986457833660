"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
const _excluded = ["active"];
var __jsx = _react.default.createElement;
const FilterMobile = _ref => {
  let {
      active
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx("svg", (0, _extends2.default)({
    width: "27",
    height: "27",
    xmlns: "http://www.w3.org/2000/svg",
    xmlLang: "http://www.w3.org/1999/xlink"
  }, props), __jsx("defs", null, __jsx("filter", {
    x: "-143.5%",
    y: "-168.8%",
    width: "387%",
    height: "512.5%",
    filterUnits: "objectBoundingBox",
    id: "a"
  }, __jsx("feOffset", {
    dy: "24",
    in: "SourceAlpha",
    result: "shadowOffsetOuter1"
  }), __jsx("feGaussianBlur", {
    stdDeviation: "40",
    in: "shadowOffsetOuter1",
    result: "shadowBlurOuter1"
  }), __jsx("feComposite", {
    in: "shadowBlurOuter1",
    in2: "SourceAlpha",
    operator: "out",
    result: "shadowBlurOuter1"
  }), __jsx("feColorMatrix", {
    values: "0 0 0 0 0.229949097 0 0 0 0 0.461254077 0 0 0 0 0.62647192 0 0 0 0.0533333333 0",
    in: "shadowBlurOuter1",
    result: "shadowMatrixOuter1"
  }), __jsx("feOffset", {
    dy: "16",
    in: "SourceAlpha",
    result: "shadowOffsetOuter2"
  }), __jsx("feGaussianBlur", {
    stdDeviation: "12",
    in: "shadowOffsetOuter2",
    result: "shadowBlurOuter2"
  }), __jsx("feComposite", {
    in: "shadowBlurOuter2",
    in2: "SourceAlpha",
    operator: "out",
    result: "shadowBlurOuter2"
  }), __jsx("feColorMatrix", {
    values: "0 0 0 0 0.231372549 0 0 0 0 0.462745098 0 0 0 0 0.62745098 0 0 0 0.0266666667 0",
    in: "shadowBlurOuter2",
    result: "shadowMatrixOuter2"
  }), __jsx("feMerge", null, __jsx("feMergeNode", {
    in: "shadowMatrixOuter1"
  }), __jsx("feMergeNode", {
    in: "shadowMatrixOuter2"
  }))), __jsx("rect", {
    id: "b",
    x: "0",
    y: "0",
    width: "92",
    height: "64",
    rx: "8"
  })), __jsx("g", {
    transform: "translate(-35 -7)",
    fill: "none",
    fillRule: "evenodd"
  }, __jsx("use", {
    fill: "#000",
    filter: "url(#a)",
    xlinkHref: "#b"
  }), __jsx("rect", {
    stroke: "#EBEBEB",
    x: ".5",
    y: ".5",
    width: "91",
    height: "63",
    rx: "8"
  }), __jsx("g", {
    stroke: "#212A36",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }, __jsx("path", {
    d: "M55.3333 14.7117a.6258.6258 0 00-.6233-.6667H37.25a.6247.6247 0 00-.6233.6667c.3012 4.194 3.357 7.6748 7.4766 8.5166v8.2292a.625.625 0 001.025.48l2.5-2.0833a.6233.6233 0 00.225-.48v-6.1459c4.1211-.8402 7.1787-4.3215 7.48-8.5166z",
    strokeWidth: "2"
  })), active && __jsx("circle", {
    stroke: "#FFF",
    strokeWidth: "2",
    fill: "#EE7845",
    cx: "56",
    cy: "13",
    r: "5"
  })));
};
var _default = exports.default = FilterMobile;